define("discourse/plugins/discourse-ai/discourse/connectors/composer-fields/persona-llm-selector", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/runloop", "@ember/service", "discourse/lib/key-value-store", "select-kit/components/dropdown-select-box", "@ember/component", "@ember/template-factory"], function (_exports, _component, _tracking, _helper, _runloop, _service, _keyValueStore, _dropdownSelectBox, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function isBotMessage(composer1, currentUser1) {
    if (composer1 && composer1.targetRecipients && currentUser1.ai_enabled_chat_bots) {
      const reciepients1 = composer1.targetRecipients.split(",");
      return currentUser1.ai_enabled_chat_bots.filter(bot1 => !bot1.is_persona).any(bot1 => reciepients1.any(username1 => username1 === bot1.username));
    }
    return false;
  }
  class BotSelector extends _component.default {
    static shouldRender(args1, container1) {
      return container1?.currentUser?.ai_enabled_personas && isBotMessage(args1.model, container1.currentUser);
    }
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "llm", [_tracking.tracked]))();
    #llm = (() => (dt7948.i(this, "llm"), void 0))();
    static #_4 = (() => dt7948.g(this.prototype, "allowLLMSelector", [_tracking.tracked], function () {
      return true;
    }))();
    #allowLLMSelector = (() => (dt7948.i(this, "allowLLMSelector"), void 0))();
    STORE_NAMESPACE = "discourse_ai_persona_selector_";
    LLM_STORE_NAMESPACE = "discourse_ai_llm_selector_";
    preferredPersonaStore = (() => new _keyValueStore.default(this.STORE_NAMESPACE))();
    preferredLlmStore = (() => new _keyValueStore.default(this.LLM_STORE_NAMESPACE))();
    constructor() {
      super(...arguments);
      if (this.botOptions && this.composer) {
        let personaId1 = this.preferredPersonaStore.getObject("id");
        this._value = this.botOptions[0].id;
        if (personaId1) {
          personaId1 = parseInt(personaId1, 10);
          if (this.botOptions.any(bot1 => bot1.id === personaId1)) {
            this._value = personaId1;
          }
        }
        this.composer.metaData = {
          ai_persona_id: this._value
        };
        this.setAllowLLMSelector();
        let llm1 = this.preferredLlmStore.getObject("id");
        llm1 = llm1 || this.llmOptions[0].id;
        if (llm1) {
          (0, _runloop.next)(() => {
            this.currentLlm = llm1;
          });
        }
      }
    }
    get composer() {
      return this.args?.outletArgs?.model;
    }
    get botOptions() {
      if (this.currentUser.ai_enabled_personas) {
        return this.currentUser.ai_enabled_personas.map(persona1 => {
          return {
            id: persona1.id,
            name: persona1.name,
            description: persona1.description
          };
        });
      }
    }
    get filterable() {
      return this.botOptions.length > 4;
    }
    get value() {
      return this._value;
    }
    set value(newValue1) {
      this._value = newValue1;
      this.preferredPersonaStore.setObject({
        key: "id",
        value: newValue1
      });
      this.composer.metaData = {
        ai_persona_id: newValue1
      };
      this.setAllowLLMSelector();
    }
    setAllowLLMSelector() {
      const persona1 = this.currentUser.ai_enabled_personas.find(innerPersona1 => innerPersona1.id === this._value);
      this.allowLLMSelector = !persona1?.force_default_llm;
    }
    get currentLlm() {
      return this.llm;
    }
    set currentLlm(newValue1) {
      this.llm = newValue1;
      const botUsername1 = this.currentUser.ai_enabled_chat_bots.find(bot1 => bot1.model_name === this.llm).username;
      this.preferredLlmStore.setObject({
        key: "id",
        value: newValue1
      });
      if (this.allowLLMSelector) {
        this.composer.set("targetRecipients", botUsername1);
      } else {
        const persona1 = this.currentUser.ai_enabled_personas.find(innerPersona1 => innerPersona1.id === this._value);
        this.composer.set("targetRecipients", persona1.username || "");
      }
    }
    get llmOptions() {
      const availableBots1 = this.currentUser.ai_enabled_chat_bots.filter(bot1 => !bot1.is_persona).filter(Boolean);
      return availableBots1.map(bot1 => {
        return {
          id: bot1.model_name,
          name: bot1.display_name
        };
      });
    }
    static #_5 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="persona-llm-selector">
          <div class="gpt-persona">
            <DropdownSelectBox
              class="persona-llm-selector__persona-dropdown"
              @value={{this.value}}
              @content={{this.botOptions}}
              @options={{hash icon="robot" filterable=this.filterable}}
            />
          </div>
          {{#if this.allowLLMSelector}}
            <div class="llm-selector">
              <DropdownSelectBox
                class="persona-llm-selector__llm-dropdown"
                @value={{this.currentLlm}}
                @content={{this.llmOptions}}
                @options={{hash icon="globe"}}
              />
            </div>
          {{/if}}
        </div>
      
    */
    {
      "id": "3PhHsEc5",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"persona-llm-selector\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"gpt-persona\"],[12],[1,\"\\n        \"],[8,[32,0],[[24,0,\"persona-llm-selector__persona-dropdown\"]],[[\"@value\",\"@content\",\"@options\"],[[30,0,[\"value\"]],[30,0,[\"botOptions\"]],[28,[32,1],null,[[\"icon\",\"filterable\"],[\"robot\",[30,0,[\"filterable\"]]]]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\"],[41,[30,0,[\"allowLLMSelector\"]],[[[1,\"        \"],[10,0],[14,0,\"llm-selector\"],[12],[1,\"\\n          \"],[8,[32,0],[[24,0,\"persona-llm-selector__llm-dropdown\"]],[[\"@value\",\"@content\",\"@options\"],[[30,0,[\"currentLlm\"]],[30,0,[\"llmOptions\"]],[28,[32,1],null,[[\"icon\"],[\"globe\"]]]]],null],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-ai/discourse/connectors/composer-fields/persona-llm-selector.js",
      "scope": () => [_dropdownSelectBox.default, _helper.hash],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = BotSelector;
});